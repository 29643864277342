#root .rnc__notification-item {
    border-radius: 15px;
    box-shadow: 0px 8px 24px 0px rgba(169, 169, 169, 0.15);
}
#root .rnc__notification-title {
    font-family: Montserrat;
    font-size: 17px;
    line-height: 21px;
}
#root .rnc__notification-message {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
